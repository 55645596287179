// Import Suade Libs first so that 'Tools' global object is registered immediately
import suadeLibs from '@veasel';

import {createApp, h} from 'vue';
import * as Sentry from '@sentry/vue';

import App from './app.vue';

// Import store before router, as the router navigation guards need to use the store to check permissions
import store from './store';
import router from './router';

// Mixins for application level functionality
import AppFunctionsMixin from '@/modules/_utils/appFunctionsMixin.js';
import SessionTimeoutMixin from '@/modules/_utils/sessionTimeoutMixin.js';
import PermissionsMixin from '@/modules/_utils/permissionsMixin.js';
import WebSocketsMixin from '@/modules/_utils/webSocketsMixin.js';

// Register permissions mixin globally to make permissions functions available in ALL child components
const vue = createApp({
  render: () => h(App),
  mixins: [AppFunctionsMixin, SessionTimeoutMixin, WebSocketsMixin],
  name: 'Suade',
  created() {
    // Request initial values
    this.initialValues().catch((err) => {
      if (err.status_code && err.status_code === 401) {
        // Authentication error, redirect handled in API service
      } else {
        console.error(err);
      }
    });
  },
});

// Most devs will be hosting their environment on localhost - so if that is the case, lets not capture stuff
if (!window.location.host.includes('localhost')) {
  const sentryDSN =
    window.location.protocol + '//d3f344cc209443828b6c13ee1644928e@' + window.location.host + '/sentry/3'; // PROD
  // const sentryDSN = 'https://a44c082f8d5d8d7e32cca0e17a0ba5c0@stats.suadelabs.com/8'; // DEV

  Sentry.init({
    app: vue,
    dsn: sentryDSN,
    tracesSampleRate: 1.0,
    replaysOnErrorSampleRate: 1.0, // For session replay
    replaysSessionSampleRate: 1.0, // For session replay
    release: 'dev', // This gets updated in App Functions Mixin
    environment: 'unverified', // Gets updated in system.js. If we get Unverified in Sentry, means something isn't working
    integrations: [Sentry.browserTracingIntegration({router})],
  });
}

vue.use(router).use(store).use(suadeLibs, {DATETIME_LOCALE: 'en-GB'});

vue.mixin(PermissionsMixin);
vue.mount('#app');

window.vue = vue;

window.addEventListener('vite:preloadError', (event) => {
  Sentry.captureMessage(`[Suade] Vite Preload Error | ${event.payload}`, 'error');
});

if (window.Cypress) {
  window.vuex = () => {
    return store;
  };
}

export default vue;
