<template>
  <div class="sl-calendar">
    <div :class="[internalView + '-view']">
      <!-- YEARLY VIEW -->
      <template v-if="internalView === 'yearly'">
        <div class="header">
          <div class="year align-left">
            {{ year }}
          </div>

          <div class="header-right-group float right">
            <s-button class="today-button" text="Today" icon="calendar" :on-click="goToToday" size="small" />
            <div class="selectors p-x-s">
              <s-icon
                class="p-x-xs"
                type="arrow-drop-left"
                color="gray"
                title="Go to previous year"
                :on-click="() => goTo(-1, 'year')"
                size="20"
              />
              <s-icon
                class="p-x-xs"
                type="arrow-drop-right"
                color="gray"
                title="Go to next year"
                :on-click="() => goTo(1, 'year')"
                size="20"
              />
            </div>
          </div>
        </div>

        <div class="table">
          <table>
            <tbody>
              <tr v-for="row in 3" :key="row">
                <td v-for="monthRowIndex in 4" :key="monthRowIndex" class="month">
                  <div
                    class="month-label"
                    title="Switch to monthly view"
                    @click="() => switchToMonthlyView(months[4 * (row - 1) + monthRowIndex - 1].date)"
                  >
                    {{ months[4 * (row - 1) + monthRowIndex - 1].date.format('month-short') }}
                  </div>
                  <div class="events">
                    <div
                      v-for="event in getEventsOfTheMonth(months[4 * (row - 1) + monthRowIndex - 1].date)"
                      :key="event.id"
                      class="event ellipsis align-left"
                      :class="{'has-click-action': event.onClick || onEventClick}"
                      :style="{'background-color': event.color || defaultColor}"
                      :title="getEventTitle(event)"
                      @click="() => eventAction(event)"
                    >
                      {{ event.name }}
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </template>

      <!-- MONTHLY VIEW -->
      <template v-if="internalView === 'monthly'">
        <div class="header">
          <div class="month-year align-left" title="Switch to yearly view" @click="() => (internalView = 'yearly')">
            <span class="month">{{ month }}&nbsp;</span>
            <span>{{ year }}</span>
          </div>

          <div class="header-right-group float right">
            <s-button text="Today" icon="calendar" :on-click="goToToday" size="small" />
            <div class="selectors p-x-s">
              <s-icon
                class="p-x-xs"
                type="arrow-drop-left"
                color="gray"
                title="Go to previous month"
                :on-click="() => goTo(-1, 'month')"
                size="20"
              />
              <s-icon
                class="p-x-xs next-month"
                type="arrow-drop-right"
                color="gray"
                title="Go to next month"
                :on-click="() => goTo(1, 'month')"
                size="20"
              />
            </div>
          </div>
        </div>

        <div class="table">
          <table>
            <thead class="days-header">
              <tr>
                <!-- no title for the week number -->
                <th title="week">w</th>
                <!-- Day of the weeks -->
                <th
                  v-for="dayIndex in 7"
                  :key="dayIndex"
                  class="align-right"
                  :class="{weekend: ['SAT', 'SUN'].includes(monthlyWeekDayLabels[dayIndex - 1])}"
                >
                  {{ monthlyWeekDayLabels[dayIndex - 1] }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(week, weekIndex) in weekNumbers" :key="weekIndex">
                <!--
                  Add this click handler to the below div when we enable weekly views again
                  @click="() => switchToWeekView(monthDays[7 * weekIndex + 3].date)"
                -->
                <td class="week-number" :title="'Week ' + week">
                  {{ week }}
                </td>

                <td
                  v-for="dayIndex in 7"
                  :key="dayIndex"
                  class="day"
                  :class="{
                    'out-of-current-month': !monthDays[7 * weekIndex + dayIndex - 1].isInCurrentMonth,
                    'in-weekend': monthDays[7 * weekIndex + dayIndex - 1].isInWeekend,
                    'is-today': monthDays[7 * weekIndex + dayIndex - 1].isToday,
                  }"
                >
                  <!--
                    Add this click handler to the below div when we enable daily views again
                    @click="() => switchToDailyView(monthDays[calcIndex(weekIndex, dayIndex)].date)"
                  -->

                  <div
                    class="day-number"
                    title="Switch to daily view"
                    :data-date="monthDays[7 * weekIndex + dayIndex - 1].date.date"
                  >
                    {{ monthDays[7 * weekIndex + dayIndex - 1].date.date }}
                  </div>
                  <div class="events" :class="['weeks-' + monthDays.length / 7]">
                    <div
                      v-for="event in getMonthlyEventsOfTheDay(monthDays[7 * weekIndex + dayIndex - 1].date)"
                      :key="event.id"
                      class="event ellipsis align-left"
                      :class="{'has-click-action': event.onClick || onEventClick}"
                      :style="{'background-color': event.color || defaultColor}"
                      :title="getEventTitle(event)"
                      @click="() => eventAction(event)"
                    >
                      {{ event.name }}
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </template>

      <!-- ******************************************************************************
            Please Note: The Daily and Weely views have been disabled because they
            are not currently working and need to be fixed. The work for this needs
            planning and will be done in this task - https://gitlab.suade.io/suade/Services/-/issues/17207
           ****************************************************************************** -->

      <!-- WEEKLY VIEW -->
      <!-- <template v-else-if="internalView === 'weekly'">
        <div class="header">
          <div class="month-year-week align-left">
            <span class="clickable month" title="Switch to monthly view" @click="() => (internalView = 'monthly')">
              <span class="bold">{{ month }}&nbsp;</span>
              <span>{{ year }}</span>
            </span>
            <span>/</span>
            <span class="bold week">week&nbsp;</span>
            <span>{{ weekNumber }}</span>
          </div>

          <div class="header-right-group float right">
            <s-button class="today-button" text="Today" icon="calendar" :on-click="goToToday" size="small" />
            <div class="selectors p-x-s">
              <s-icon
                class="p-x-xs"
                type="arrow-drop-left"
                color="gray"
                title="Go to previous week"
                :on-click="() => goTo(-7, 'day')"
                size="20"
              />
              <s-icon
                class="p-x-xs"
                type="arrow-drop-right"
                color="gray"
                title="Go to next week"
                :on-click="() => goTo(7, 'day')"
                size="20"
              />
            </div>
          </div>
        </div>

        <div class="table">
          <table>
            <thead class="days-header">
              <tr>
                <th></th>

                <th
                  v-for="(weekDay, index) in weekDays"
                  :key="index"
                  class="align-left"
                  :class="{
                    weekend: weekDay.isInWeekend,
                    today: weekDay.isToday,
                    'out-of-current-month': !weekDay.isInCurrentMonth,
                  }"
                  title="Switch to daily view"
                  @click="() => switchToDailyView(weekDay.date)"
                >
                  {{ weekDay.date.format('weekday-short').toUpperCase() + ' ' + weekDay.date.date }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>All Day</td>
                <td
                  v-for="(day, index) in weekDays"
                  :key="index"
                  class="day"
                  :class="{
                    'out-of-current-month': !day.isInCurrentMonth,
                    'in-weekend': day.isInWeekend,
                    'is-today': day.isToday,
                  }"
                >
                  <div class="on-all-day">
                    <span
                      v-for="event in getEventsOfTheDay(day.date).onAllDay"
                      :key="event.id"
                      class="event ellipsis align-left"
                      :class="{'has-click-action': event.onClick || onEventClick}"
                      :style="{'background-color': event.color || defaultColor}"
                      :title="getEventTitle(event)"
                      @click="() => eventAction(event)"
                    >
                      {{ event.name }}
                    </span>
                  </div>
                </td>
              </tr>

              <tr>
                <td class="hours">
                  <div>00:00</div>
                  <div>04:00</div>
                  <div>08:00</div>
                  <div>12:00</div>
                  <div>16:00</div>
                  <div>20:00</div>
                  <div>00:00</div>
                </td>
                <td
                  v-for="(day, index) in weekDays"
                  :key="index"
                  class="day"
                  :class="{
                    'out-of-current-month': !day.isInCurrentMonth,
                    'in-weekend': day.isInWeekend,
                    'is-today': day.isToday,
                  }"
                >
                  <div class="not-on-all-day">
                    <div
                      v-for="event in getEventsOfTheDay(day.date).notOnAllDay"
                      :key="event.id"
                      class="event ellipsis align-left"
                      :class="{'has-click-action': event.onClick || onEventClick}"
                      :style="{
                        'background-color': event.color || defaultColor,
                        height: event.height + 'px',
                        top: event.top + 'px',
                        left: event.leftWidthOffset * 165 + 'px',
                      }"
                      :title="getEventTitle(event)"
                      @click="() => eventAction(event)"
                    >
                      {{ event.name }}
                    </div>

                    <div class="hour-dashed-lines">
                      <div v-for="index in 7" :key="index" class="line"></div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </template> -->

      <!-- DAILY VIEW -->
      <!-- <template v-else-if="internalView === 'daily'">
        <div class="header">
          <div class="month-year-week align-left">
            <span class="clickable month" title="Switch to monthly view" @click="() => (internalView = 'monthly')">
              <span class="bold">{{ month }}&nbsp;</span>
              <span>{{ year }}</span>
            </span>
            <span>/</span>
            <span class="clickable week" title="Switch to weekly view" @click="switchToWeekView(day.date)">
              <span class="bold">week&nbsp;</span>
              <span>{{ weekNumber }}</span>
            </span>
            <span>/</span>
            <span class="bold">{{ day.date.format('weekday-short') }}&nbsp;</span>
            <span>{{ day.date.date }}</span>
          </div>

          <div class="header-right-group float right">
            <s-button text="Today" icon="calendar" :on-click="goToToday" size="small" />
            <div class="selectors p-x-s">
              <s-icon
                class="p-x-xs"
                type="arrow-drop-left"
                color="gray"
                title="Go to previous week"
                :on-click="() => goTo(-1, 'day')"
                size="20"
              />
              <s-icon
                class="p-x-xs"
                type="arrow-drop-right"
                color="gray"
                title="Go to next week"
                :on-click="() => goTo(1, 'day')"
                size="20"
              />
            </div>
          </div>
        </div>

        <div class="table">
          <table>
            <tbody>
              <tr>
                <td>All Day</td>
                <td
                  class="day"
                  :class="{
                    'out-of-current-month': !day.isInCurrentMonth,
                    'in-weekend': day.isInWeekend,
                    'is-today': day.isToday,
                  }"
                >
                  <div class="on-all-day">
                    <span
                      v-for="event in getEventsOfTheDay(internalDatetimeFocused).onAllDay"
                      :key="event.id"
                      class="event ellipsis align-left"
                      :class="{'has-click-action': event.onClick || onEventClick}"
                      :style="{'background-color': event.color || defaultColor}"
                      :title="getEventTitle(event)"
                      @click="() => eventAction(event)"
                    >
                      {{ event.name }}
                    </span>
                  </div>
                </td>
              </tr>

              <tr>
                <td class="hours">
                  <div>00:00</div>
                  <div>04:00</div>
                  <div>08:00</div>
                  <div>12:00</div>
                  <div>16:00</div>
                  <div>20:00</div>
                  <div>00:00</div>
                </td>
                <td
                  class="day"
                  :class="{
                    'out-of-current-month': !day.isInCurrentMonth,
                    'in-weekend': day.isInWeekend,
                    'is-today': day.isToday,
                  }"
                >
                  <div class="not-on-all-day">
                    <div
                      v-for="event in getEventsOfTheDay(internalDatetimeFocused).notOnAllDay"
                      :key="event.id"
                      class="event ellipsis align-left"
                      :class="{'has-click-action': event.onClick || onEventClick}"
                      :style="{
                        'background-color': event.color || defaultColor,
                        height: event.height + 'px',
                        top: event.top + 'px',
                        left: event.leftWidthOffset * 165 + 'px',
                      }"
                      :title="getEventTitle(event)"
                      @click="() => eventAction(event)"
                    >
                      {{ event.name }}
                    </div>

                    <div class="hour-dashed-lines">
                      <div v-for="index in 7" :key="index" class="line"></div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </template> -->
    </div>
  </div>
</template>

<script>
  import {extend} from '@veasel/core/tools';
  import {STime} from '@veasel/core/time';
  import yearly from '../utils/yearly';
  import monthly from '../utils/monthly';
  import weekly from '../utils/weekly';
  import daily from '../utils/daily';

  import button from '@veasel/base/button';
  import icon from '@veasel/base/icon';
  import {colorsMixin} from '@veasel/core/colors';

  // available calendar views
  // const VIEWS = ['yearly', 'monthly', 'weekly', 'daily'];
  const VIEWS = ['yearly', 'monthly'];

  export default {
    name: 's-calendar',

    description: 'A calendar to display events on different scopes, from daily to yearly (based on STime)',

    components: {
      's-button': button,
      's-icon': icon,
    },

    mixins: [yearly, monthly, weekly, daily, colorsMixin],

    emits: ['view-changed', 'day-range-changed'],

    props: {
      events: {
        description: `The list of events to display.
      <ul style="margin-left: 20px;">
        <li><b>id*</b> String : the event key</li>
        <li><b>name*</b> String : the displayed event name</li>
        <li><b>start*</b> STime: date/datetime : the start of the event</li>
        <li><b>end</b> STime: date/datetime : the end of the event</li>
        <li><b>color</b> String: the color of the event (light blue by default)</li>
        <li><b>onClick</b> Function: called on event click (if undefined, the <em>onEventClick</em> prop function is called)</li>
      </ul>`,
        type: Array,
        required: true,
      },
      onEventClick: {
        description: 'The function triggered when clicking on a calendar event.',
        type: Function,
      },
      view: {
        description: 'The scope of the calendar view.',
        type: String,
        values: VIEWS,
        validator: (val) => VIEWS.includes(val),
        default: 'monthly',
      },
    },

    data: function () {
      return {
        internalView: undefined,

        internalDatetimeFocused: undefined,

        colors: this.s_colors,
        defaultColor: 'rgba(185, 219, 255)', // pastel blue
      };
    },

    computed: {
      dayHeight: function () {
        return this.internalView === 'weekly' ? 312 : 342;
      },
    },

    watch: {
      view: function (val) {
        this.internalView = val;
      },
      datetimeFocused: function (val) {
        this.internalDatetimeFocused = val;
      },
      internalView: function (val) {
        this.$emit('view-changed', val);
      },
    },

    methods: {
      goToToday: function () {
        this.internalDatetimeFocused.setDate(this.internalDatetimeFocused, Date.now());
      },

      calcIndex(weekIndex, dayIndex) {
        return 7 * weekIndex + dayIndex - 1;
      },

      getEventTitle: function (event) {
        const formatter = (date) => new STime(date).format(event?.dateOnly ? 'date-short' : 'time-short-date-short');

        if (event?.end) {
          return event.name + '\nfrom ' + formatter(event.start) + '  to  ' + formatter(event.end);
        } else {
          return event?.name + '\n' + (event?.dateOnly ? 'on' : 'at') + ' ' + formatter(event?.start);
        }
      },

      goTo: function (index, type) {
        this.internalDatetimeFocused.add(index, type);
      },

      getEventsOfTheDay: function (day) {
        const dayDate = day.format('dateNumeric');
        const startOfDay = day.startOf('day');
        const endOfDay = day.endOf('day');
        const eventsOfTheDay = {onAllDay: [], notOnAllDay: []};

        for (let i = 0; i < this.events.length; i++) {
          const event = extend(this.events[i]);

          this.setEventHeight(event, startOfDay, endOfDay);
          this.setEventTop(event, startOfDay);

          if (!event.end && event.start && event.start.format('dateNumeric') === dayDate) {
            eventsOfTheDay.notOnAllDay.push(event);
          } else if (
            (event.start && event.start.format('dateNumeric') === dayDate) ||
            (event.event && event.end.format('dateNumeric') === dayDate) ||
            (event.start && event.end && day.isBetween(event.start, event.end))
          ) {
            event.isOnAllDay = event.start.isBefore(startOfDay) && event.end.isAfter(endOfDay);
            event.isOnAllDay ? eventsOfTheDay.onAllDay.push(event) : eventsOfTheDay.notOnAllDay.push(event);
          }
        }

        eventsOfTheDay.onAllDay.sort((a, b) => a.start.valueOf() - b.start.valueOf());
        eventsOfTheDay.notOnAllDay.sort((a, b) => a.start.valueOf() - b.start.valueOf());
        eventsOfTheDay.notOnAllDay = this.setEventsLeft(eventsOfTheDay.notOnAllDay);

        return eventsOfTheDay;
      },

      setEventHeight: function (event, startOfDay, endOfDay) {
        event.height = 16;
        if (event.end) {
          const start = event.start && event.start.isAfter(startOfDay) ? event.start : startOfDay;
          const end = event.end.isBefore(endOfDay) ? event.end : endOfDay;
          event.height = Math.round((STime.diff(start, end).asHours() * this.dayHeight) / 24);
        }

        if (event.height < 16) {
          event.height = 16;
        }
      },

      setEventTop: function (event, startOfDay) {
        if (!event.start || event.start.isBefore(startOfDay)) {
          event.top = 8;
        } else {
          event.top = Math.round((STime.diff(event.start, startOfDay).asHours() * this.dayHeight) / 24) + 8;
        }
      },

      setEventsLeft: function (events) {
        const positionedEvents = [];
        let leftWidthOffset = 0;

        for (let i = 0; i < events.length; i++) {
          const event = events[i];
          if (!positionedEvents.some((e) => e.id === event.id)) {
            event.leftWidthOffset = 0;
            positionedEvents.push(event);
            const intersectedEvents = this.getIntersectedEvents(event, events);

            for (let j = 0; j < intersectedEvents.length; j++) {
              const intersectedEvent = extend(intersectedEvents[j]);
              if (!positionedEvents.some((e) => e.id === intersectedEvent.id)) {
                if (j === 0) {
                  leftWidthOffset++;
                } else {
                  const intersectedPositionedEvents = this.getIntersectedEvents(intersectedEvent, positionedEvents);
                  leftWidthOffset =
                    intersectedPositionedEvents[intersectedPositionedEvents.length - 1].leftWidthOffset + 1;
                }

                intersectedEvent.leftWidthOffset = leftWidthOffset;
                positionedEvents.push(intersectedEvent);
              }
            }
          }
        }

        return positionedEvents;
      },

      getIntersectedEvents: function (event, events) {
        const intersectedEvents = events.filter((e) => {
          if (e.id !== event.id) {
            if (event.end && e.end) {
              return (
                (e.start.isAfter(event.start) && e.start.isBefore(event.end)) ||
                (e.end.isAfter(event.start) && e.end.isBefore(event.end)) ||
                (event.start.isAfter(e.start) && event.start.isBefore(e.end)) ||
                (event.end.isAfter(e.start) && event.end.isBefore(e.end))
              );
            } else {
              const heightDiff = Math.round((STime.diff(event.start, e.start).asHours() * 306) / 24);
              return Math.abs(heightDiff) <= 16;
            }
          } else {
            return false;
          }
        });

        return intersectedEvents;
      },

      emitDayRangeEvent: function () {
        this.$nextTick(() => {
          let firstDay;
          let lastDay;

          switch (this.internalView) {
            case 'yearly':
              firstDay = this.firstYearDay;
              lastDay = this.lastYearDay;
              break;
            case 'monthly':
              firstDay = this.firstMonthDay;
              lastDay = this.lastMonthDay;
              break;
            case 'weekly':
              firstDay = this.firstWeekDay;
              lastDay = this.lastWeekDay;
              break;
            case 'daily':
              firstDay = this.day.date;
              lastDay = this.day.date;
              break;
          }

          this.$emit('day-range-changed', {
            firstDay: firstDay.format('YYYY-MM-DD'),
            lastDay: lastDay.format('YYYY-MM-DD'),
          });
        });
      },

      eventAction: function (event) {
        if (event.onClick) {
          event.onClick(event);
        } else {
          if (this.onEventClick) {
            this.onEventClick(event);
          }
        }
      },
    },

    created() {
      this.internalDatetimeFocused = new STime(Date.now(), 'naive');
      this.internalView = this.view;
    },
  };
</script>

<style lang="scss" scoped>
  @use 'sass:color';
  @import '@veasel/core';

  .clickable {
    cursor: pointer;
  }

  .sl-calendar {
    background-color: var(--background-main);
    height: 100%;
    color: var(--main-dark);

    .header {
      &-right-group {
        .selectors,
        .selectors .s-icon {
          display: inline-block;
          vertical-align: middle;
        }

        .selectors .s-icon {
          cursor: pointer;

          &:hover ::v-deep(svg) {
            fill: var(--main-dark);
          }
        }
      }
    }

    .yearly-view {
      .header {
        height: 50px;
        color: $gray-darker;
        font-size: 22px;

        .year {
          font-size: 23px;
          font-family: $font-family;
          font-weight: 900;
          position: relative;
          float: left;
          top: 10px;
          left: 10px;
          width: 170px;
        }
      }

      .table {
        padding: 0 10px 10px 10px;

        table {
          width: 100%;
          table-layout: fixed;
          border-collapse: collapse;
          padding: 50px;

          tbody tr {
            border-top: 2px solid $lightgray;

            &:last-child td div.events {
              height: 111px;
            }
          }

          td {
            padding: 5px;

            &:not(:first-child) {
              border-left: 2px solid $lightgray;
            }

            div {
              font-size: 14px;
              font-family: $font-family;

              &.month-label {
                top: 0;
                text-align: right;
                font-weight: 900;
                font-size: 13px;
                cursor: pointer;
              }

              &.events {
                height: 116px;
                overflow-x: hidden;

                .event {
                  font-family: $font-family;
                  color: $black;
                  padding-left: 5px;
                  border-radius: 2px;
                  margin-bottom: 2px;
                  width: auto;
                  max-width: 94%;

                  &.has-click-action {
                    cursor: pointer;
                  }
                }
              }
            }
          }
        }
      }
    }

    .monthly-view {
      .header {
        height: 50px;
        color: $gray-darker;
        font-size: 22px;

        .month-year {
          font-size: 23px;
          font-family: $font-family;
          position: relative;
          float: left;
          top: 10px;
          left: 10px;
          width: 170px;
          cursor: pointer;

          .month {
            font-weight: 900;
          }

          &:hover {
            color: $black;
          }
        }
      }

      .table {
        padding: 0 10px 10px 10px;

        table {
          width: 100%;
          table-layout: fixed;
          border-collapse: collapse;
          padding: 50px;

          tbody tr {
            border-top: 2px solid $lightgray;

            &:last-child td div.events.weeks-5 {
              height: 49px;
            }
          }

          td,
          th {
            padding: 5px;

            &:first-child {
              width: 5.5%;
              border-right: 2px solid $lightgray;
            }

            &:not(:first-child) {
              width: 13.5%;
              border-left: 2px solid $lightgray;
            }

            div {
              font-size: 14px;
              font-family: $font-family;

              &.day-number {
                top: 0;
                text-align: right;
                font-weight: 900;
                font-size: 13px;

                // uncomment when day view is re-enabled
                // cursor: pointer;
                // &:hover {
                //   color: $focus-blue;
                // }
              }

              &.events {
                overflow-x: hidden;

                &.weeks-4 {
                  height: 72px;
                }

                &.weeks-5 {
                  height: 53px;
                }

                &.weeks-6 {
                  height: 39px;
                }

                .event {
                  font-family: $font-family;
                  color: $black;
                  padding-left: 5px;
                  border-radius: 2px;
                  margin-bottom: 2px;
                  width: auto;
                  max-width: 90%;

                  &.has-click-action {
                    cursor: pointer;
                  }
                }
              }
            }
          }

          .week-number {
            color: $black;
            text-align: center;
            font-family: $font-family;
            font-weight: 900;
            font-size: 12px;
            font-style: italic;

            // uncomment when week view is re-enabled
            // cursor: pointer;
            // &:hover {
            //   color: $link-hover-color;
            // }
          }

          .days-header {
            font-size: 15px;
            color: $gray-darker;
            font-weight: 900;

            .weekend {
              background-color: color.adjust($lightgray, $lightness: 4%);
            }
          }

          .day {
            &.out-of-current-month {
              color: $gray;
            }

            &.in-weekend {
              background-color: color.adjust($lightgray, $lightness: 4%);
            }

            &.is-today {
              div.day-number {
                color: $focus-blue;
              }
            }
          }
        }
      }
    }

    .weekly-view {
      .header {
        height: 50px;
        color: $gray-darker;
        font-size: 22px;

        .month-year-week {
          font-size: 23px;
          font-family: $font-family;
          position: relative;
          float: left;
          top: 10px;
          left: 10px;
          width: 275px;

          .month {
            &:hover {
              color: $black;
            }
          }
        }
      }

      .table {
        padding: 0 10px 10px 10px;

        table {
          width: 100%;
          table-layout: fixed;
          border-collapse: collapse;
          padding: 50px;

          tbody tr {
            border-top: 2px solid $lightgray;
          }

          td,
          th {
            &:first-child {
              width: 5.5%;
            }

            &:not(:first-child) {
              width: 13.5%;
              border-left: 2px solid $lightgray;
            }

            div {
              font-family: $font-family;

              &.day-number {
                top: 0;
                text-align: right;
                font-weight: 900;
                font-size: 13px;
              }

              .event {
                font-size: 14px;
                font-family: $font-family;
                color: $black;
                padding: 0 5px 0 5px;
                border-radius: 2px;
                margin: 2px 4px 2px 4px;
                width: auto;

                &.has-click-action {
                  cursor: pointer;
                }
              }

              &.on-all-day {
                display: -webkit-box;
                overflow-x: auto;
                overflow-y: hidden;
                height: 43px;

                .event {
                  position: relative;
                  top: 8px;
                  height: 17px;
                }
              }

              &.not-on-all-day {
                height: 345px;
                overflow: auto;
                position: relative;

                .event {
                  width: 150px;
                  position: absolute;
                  z-index: 1;
                }

                .hour-dashed-lines {
                  position: sticky;
                  top: 9px;

                  .line {
                    width: 100%;
                  }

                  .line:first-child {
                    border-bottom: 1px dashed $lightgray;
                    position: relative;
                  }

                  .line:not(:first-child) {
                    border-bottom: 1px dashed $lightgray;
                    position: relative;
                    padding-top: 51px;
                  }
                }
              }
            }
          }

          th {
            padding: 5px;
            cursor: pointer;
          }

          td {
            &:first-child {
              @include base-alt;

              font-size: 12px;
            }

            &.hours {
              position: relative;
              bottom: 6px;

              div:not(:first-child) {
                position: relative;
                margin-top: 38px;
              }
            }
          }

          .week-number {
            text-align: center;
            font-family: $font-family;
            font-weight: 900;
            font-size: 12px;
            font-style: italic;
          }

          .days-header {
            font-size: 15px;
            color: $gray-darker;
            font-weight: 900;

            .weekend {
              background-color: color.adjust($lightgray, $lightness: 4%);
            }

            .today {
              color: $focus-blue;
            }

            .out-of-current-month {
              color: $gray;
            }
          }

          .day {
            &.out-of-current-month {
              color: $gray;
            }

            &.in-weekend {
              background-color: color.adjust($lightgray, $lightness: 4%);
            }

            &.is-today {
              div.day-number {
                color: $focus-blue;
              }
            }
          }
        }
      }
    }

    .daily-view {
      .header {
        height: 50px;
        color: $gray-darker;
        font-size: 22px;

        .month-year-week {
          font-size: 23px;
          font-family: $font-family;
          position: relative;
          float: left;
          top: 10px;
          left: 10px;
          width: 400px;

          .month,
          .week {
            cursor: pointer;

            &:hover {
              color: $black;
            }
          }
        }
      }

      .table {
        padding: 0 10px 10px 10px;

        table {
          width: 100%;
          table-layout: fixed;
          border-collapse: collapse;
          padding: 50px;

          tbody tr:not(:first-child) {
            border-top: 2px solid $lightgray;
          }

          td {
            &:first-child {
              @include base-alt;

              width: 5.5%;
              font-size: 12px;
            }

            &:not(:first-child) {
              width: 94.5%;
              border-left: 2px solid $lightgray;
            }

            &.hours {
              position: relative;
              bottom: 6px;

              div:not(:first-child) {
                position: relative;
                margin-top: 43px;
              }
            }

            div {
              font-family: $font-family;

              .event {
                font-size: 14px;
                font-family: $font-family;
                color: $black;
                padding: 0 5px 0 5px;
                border-radius: 2px;
                margin: 2px 4px 2px 4px;
                width: auto;

                &.has-click-action {
                  cursor: pointer;
                }
              }

              &.on-all-day {
                display: -webkit-box;
                overflow-x: auto;
                overflow-y: hidden;
                height: 43px;

                .event {
                  position: relative;
                  top: 8px;
                  height: 17px;
                }
              }

              &.not-on-all-day {
                height: 375px;
                overflow: auto;
                position: relative;

                .event {
                  width: 150px;
                  position: absolute;
                  z-index: 1;
                }

                .hour-dashed-lines {
                  position: sticky;
                  top: 9px;

                  .line {
                    width: 100%;
                  }

                  .line:first-child {
                    border-bottom: 1px dashed $lightgray;
                    position: relative;
                  }

                  .line:not(:first-child) {
                    border-bottom: 1px dashed $lightgray;
                    position: relative;
                    padding-top: 56px;
                  }
                }
              }
            }
          }

          .week-number {
            text-align: center;
            font-family: $font-family;
            font-weight: 900;
            font-size: 12px;
            font-style: italic;
          }

          .day {
            &.out-of-current-month {
              color: $gray;
            }

            &.in-weekend {
              background-color: color.adjust($lightgray, $lightness: 4%);
            }

            &.is-today {
              div.day-number {
                color: $focus-blue;
              }
            }
          }
        }
      }
    }

    ::-webkit-scrollbar {
      width: 4px;
      height: 5px;
      background-color: $lightgray; /* or add it to the track */
      z-index: 41;
    }

    ::-webkit-scrollbar-thumb {
      background-color: color.adjust($gray, $lightness: 10%);
      z-index: 41;
      border-radius: 2px;

      &:hover {
        background-color: color.adjust($gray, $lightness: -10%);
      }
    }
  }
</style>
